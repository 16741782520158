// ************************* Size ****************************
// Width *****************************************************
.w {
  &-1\/12 {
    width: calc(100% / 12 * 1);
  }

  &-2\/12,
  &-1\/6 {
    width: calc(100% / 12 * 2);
  }

  &-1\/5 {
    width: calc(100% / 5 * 1);
  }

  &-3\/12,
  &-1\/4 {
    width: calc(100% / 12 * 3);
  }

  &-4\/12,
  &-2\/6,
  &-1\/3 {
    width: calc(100% / 12 * 4);
  }

  &-2\/5 {
    width: calc(100% / 5 * 2);
  }

  &-5\/12 {
    width: calc(100% / 12 * 5);
  }

  &-6\/12,
  &-3\/6,
  &-2\/4 {
    width: calc(100% / 12 * 6);
  }

  &-7\/12 {
    width: calc(100% / 12 * 7);
  }

  &-3\/5 {
    width: calc(100% / 5 * 3);
  }

  &-8\/12,
  &-4\/6,
  &-2\/3 {
    width: calc(100% / 12 * 8);
  }

  &-9\/12,
  &-3\/4 {
    width: calc(100% / 12 * 9);
  }

  &-4\/5 {
    width: calc(100% / 5 * 4);
  }

  &-10\/12,
  &-5\/6 {
    width: calc(100% / 12 * 10);
  }

  &-11\/12 {
    width: calc(100% / 12 * 11);
  }

  &-full {
    width: 100%;

    &-view {
      width: 100vw;
    }
  }
}

// Height ****************************************************
.h {
  &-1\/12 {
    height: calc(100% / 12 * 1);
  }

  &-2\/12,
  &-1\/6 {
    height: calc(100% / 12 * 2);
  }

  &-1\/5 {
    height: calc(100% / 5 * 1);
  }

  &-3\/12,
  &-1\/4 {
    height: calc(100% / 12 * 3);
  }

  &-4\/12,
  &-2\/6,
  &-1\/3 {
    height: calc(100% / 12 * 4);
  }

  &-2\/5 {
    height: calc(100% / 5 * 2);
  }

  &-5\/12 {
    height: calc(100% / 12 * 5);
  }

  &-6\/12,
  &-3\/6,
  &-2\/4 {
    height: calc(100% / 12 * 6);
  }

  &-7\/12 {
    height: calc(100% / 12 * 7);
  }

  &-3\/5 {
    height: calc(100% / 5 * 3);
  }

  &-8\/12,
  &-4\/6,
  &-2\/3 {
    height: calc(100% / 12 * 8);
  }

  &-9\/12,
  &-3\/4 {
    height: calc(100% / 12 * 9);
  }

  &-4\/5 {
    height: calc(100% / 5 * 4);
  }

  &-10\/12,
  &-5\/6 {
    height: calc(100% / 12 * 10);
  }

  &-11\/12 {
    height: calc(100% / 12 * 11);
  }

  &-full {
    height: 100%;

    &-view {
      height: 100vh;
    }
  }
}

// ************************ Display ***************************
// Block ******************************************************
.block {
  display: block;
}

// Contents ***************************************************
.contents {
  display: contents;
}

// Flex *******************************************************
.flex {
  display: flex;
}

// Flow Root **************************************************
.flow-root {
  display: flow-root;
}

// Grid *******************************************************
.grid {
  display: grid;
}

// Hidden *****************************************************
.hidden {
  display: none;
}

// Inline *****************************************************
.inline {
  display: inline;
}

// Inline Block ***********************************************
.inline-block {
  display: inline-block;
}

// Inline-Flex ************************************************
.inline-flex {
  display: inline-flex;
}

// Inline Grid ************************************************
.inline-grid {
  display: inline-grid;
}

// Inline-Table ***********************************************
.inline-table {
  display: inline-table;
}

// List Item **************************************************
.list-item {
  display: list-item;
}

// Table ******************************************************
.table {
  display: table;
}

// Table Caption **********************************************
.table-caption {
  display: table-caption;
}

// Table Cell *************************************************
.table-cell {
  display: table-cell;
}

// Table Column ***********************************************
.table-column {
  display: table-column;
}

// Table Column Group *****************************************
.table-column-group {
  display: table-column-group;
}

// Table Footer Group *****************************************
.table-footer-group {
  display: table-footer-group;
}

// Table Header Group *****************************************
.table-header-group {
  display: table-header-group;
}

// Table Row **************************************************
.table-row {
  display: table-row;
}

// Table Row Group ********************************************
.table-row-group {
  display: table-row-group;
}

// ************************* Flex ****************************
// Flex Align Content ****************************************
.content {
  &-baseline {
    align-content: baseline;
  }

  &-center {
    align-content: center;
  }

  &-end {
    align-content: flex-end;
  }

  &-start {
    align-content: flex-start;
  }

  &-normal {
    align-content: normal;
  }

  &-between {
    align-content: space-between;
  }

  &-around {
    align-content: space-around;
  }

  &-evenly {
    align-content: space-evenly;
  }

  &-stretch {
    align-content: stretch;
  }
}

// Flex Align Items ******************************************
.items {
  &-baseline {
    align-items: baseline;
  }

  &-center {
    align-items: center;
  }

  &-end {
    align-items: flex-end;
  }

  &-start {
    align-items: flex-start;
  }

  &-stretch {
    align-items: stretch;
  }
}

// Align Self ************************************************
.self {
  &-auto {
    align-self: auto;
  }

  &-baseline {
    align-self: baseline;
  }

  &-center {
    align-self: center;
  }

  &-end {
    align-self: flex-end;
  }

  &-start {
    align-self: flex-start;
  }

  &-stretch {
    align-self: stretch;
  }
}

// Flex Justify Content **************************************
.justify {
  &-center {
    justify-content: center;
  }

  &-end {
    justify-content: flex-end;
  }

  &-start {
    justify-content: flex-start;
  }

  &-normal {
    justify-content: normal;
  }

  &-between {
    justify-content: space-between;
  }

  &-around {
    justify-content: space-around;
  }

  &-evenly {
    justify-content: space-evenly;
  }

  &-stretch {
    justify-content: stretch;
  }
}

// Flex Justify Items ****************************************
.justify-items {
  &-center {
    justify-items: center;
  }

  &-end {
    justify-items: end;
  }

  &-start {
    justify-items: start;
  }

  &-stretch {
    justify-items: stretch;
  }
}

// Flex Justify Self *****************************************
.justify-self {
  &-auto {
    justify-self: auto;
  }

  &-center {
    justify-self: center;
  }

  &-end {
    justify-self: end;
  }

  &-start {
    justify-self: start;
  }

  &-stretch {
    justify-self: stretch;
  }
}

// Flex Direction *****************************************
.direction {
  &-col {
    flex-direction: column;
  }

  &-row {
    flex-direction: row;
  }
}

.flex-center {
  justify-content: center;
  align-items: center;
}

// Flex Wrap *****************************************
.wrap {
  flex-wrap: wrap;
}

.no-wrap {
  flex-wrap: nowrap;
}

// Border Radius **************************************

.radius-circle {
  border-radius: 50%;
}

// Font Weight Bold ****************************************
.bold {
  &-600 {
    font-weight: 600;
  }

  &-700 {
    font-weight: 700;
  }

  &-800 {
    font-weight: 800;
  }

  &-900 {
    font-weight: 900;
  }
}

// Icon sizes ****************************************
.icon {
  &-18 {
    height: 18px;
    width: 18px;
  }

  &-20 {
    height: 20px;
    width: 20px;
  }

  &-22 {
    height: 22px;
    width: 22px;
  }

  &-24 {
    height: 24px;
    width: 24px;
  }

  &-26 {
    height: 26px;
    width: 26px;
  }
}
