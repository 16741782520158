// @import url("https://fonts.googleapis.com/css2?family=Gantari");
@font-face {
  font-family: 'Gantari';
  src: url('/assets/fonts/Gantari-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gantari';
  src: url('/assets/fonts/Gantari-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gantari';
  src: url('/assets/fonts/Gantari-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gantari';
  src: url('/assets/fonts/Gantari-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

* {
  font-family: 'Gantari', Helvetica, Arial, Verdana, Tahoma, sans-serif;
  scroll-margin-top: 5rem;

}

body {
  --ion-background-color: var(--color-base-white);
  --ion-text-color: var(--color-neutral-20);
  --ion-background-color-step-800: var(--color-base-white);
  --border-radius: 8px !important;
  --ion-color-primary: var(--color-primary-50);
  --ion-toolbar-background: var(--color-base-white);
  --ion-item-background: var(--color-base-white);
  --ion-color-primary-contrast: var(--color-base-white);
  --ion-text-color-rgb: 200, 155, 155;
  --ion-grid-column-padding: 12px;
  --ion-grid-padding: 0;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

ion-icon.star {
  cursor: pointer;
  color: var(--color-yellow-50);
  height: 26px !important;
  width: 26px !important;
  min-height: 26px !important;
  min-width: 26px !important;
  transition: background-color 0.4s cubic-bezier(0.25, 1, 0.5, 1),
    transform 0.4s cubic-bezier(0.25, 1, 0.5, 1),
    box-shadow 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}

ion-spinner {
  height: 26px !important;
  width: 26px !important;
  min-height: 26px !important;
  min-width: 26px !important;
}

ion-icon.star:hover {
  transform: scale(1.1);
}

ion-button {
  text-transform: none;
  --box-shadow: none;
}

ion-skeleton-text {
  --background: radial-gradient(0, 159, 227, transparent);
  --background-rgb: 0, 159, 227;
  margin: 0;
  // --background: radial-gradient(200, 155, 155, transparent);
  // --background-rgb: 200, 155, 155;
}

ion-modal {
  --border-radius: 16px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

  ion-content::part(scroll) {
    background-color: var(--color-background-nav);
  }
}

a,
article,
ion-breadcrumb {
  cursor: pointer;
}

a.link-view {
  color: var(--color-primary-50);
}

a.link-view:active {
  color: var(--color-primary-70);
}

.display-none {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

/*Custom mobile ionic header box shadow*/
.header-md {
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

ion-breadcrumb.middle::part(native) {
  padding-right: 12px !important;
  color: var(--neutral-50, rgba(120, 117, 121, 1));
}

ion-button.submit::part(native) {
  background: var(--color-button-submit);
  color: var(--color-background-nav);
}

button:disabled,
input:read-only {
  opacity: 0.5;
  background-color: var(--color-neutral-40);
  cursor: not-allowed;
}

.transparent {
  background-color: transparent !important;
}

ion-toast.success {
  --background: var(--color-background-nav);
  --border-color: var(--color-success-50);
  --border-style: solid;
  --border-width: 0px 0px 0px 10px;
  --border-radius: 4px;

  &::part(header) {
    color: var(--color-neutral-20);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  &::part(message) {
    color: var(--color-neutral-40);
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }

  &::part(icon) {
    color: var(--color-base-white);
    background-color: var(--color-success-50);
    width: 24px;
    height: 24px;
    border-radius: 100%;
    padding: 4px;
  }
}

ion-toast.error {
  --background: var(--color-background-nav);
  --border-color: var(--color-error-50);
  --border-style: solid;
  --border-width: 0px 0px 0px 10px;
  --border-radius: 4px;

  &::part(header) {
    color: var(--color-neutral-20);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  &::part(message) {
    color: var(--color-neutral-40);
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.5px;
  }

  &::part(icon) {
    color: var(--color-base-white);
    background-color: var(--color-error-50);
    width: 24px;
    height: 24px;
    border-radius: 100%;
    padding: 4px;
  }
}

ion-toast::part(button) {
  color: var(--color-neutral-20);
}

.toast-layout-baseline .toast-content {
  padding: 0 !important;
}

ion-content {
  --background: var(--color-background-content) !important;
}

ion-content::part(scroll) {
  background-color: var(--color-background-content);
}

ion-chip {
  --background: var(--color-primary-90);
  --color: var(--color-chip);
  border-radius: 8px;

  ion-icon {
    color: var(--color-chip);
  }
}

ion-toolbar {
  contain: layout !important;
}

ion-popover::part(backdrop) {
  cursor: default;
}

ion-popover {
  --box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  --offset-x: -8px;
  --offset-y: 2px;
}

.color-red-50 {
  color: var(--color-error-50);
}

@media all and (max-width: 940px) {
  body {
    --ion-grid-column-padding: 6px;
    --ion-grid-padding: 0;
  }

  .slide-mobile,
  .skeleton-mobile {
    display: block;
  }

  .slide-desktop,
  .skeleton-desktop {
    display: none !important;
  }
}

@media all and (min-width: 940px) {

  .slide-mobile,
  .skeleton-mobile {
    display: none;
  }

  .skeleton-desktop {
    display: flex;
  }

  .slide-desktop {
    display: block !important;
  }
}
