:root {
  // ************************ Colors ***************************
  // Blue ******************************************************
  --color-blue-10: #00202D;
  --color-blue-20: #00405B;
  --color-blue-30: #005F88;
  --color-blue-40: #007FB6;
  --color-blue-50: #009FE3;
  --color-blue-60: #33B2E9;
  --color-blue-70: #66C5EE;
  --color-blue-80: #99D9F4;
  --color-blue-90: #CCECF9;
  --color-blue-95: #E6F5FC;
  // Blue Dark *************************************************
  --color-blue-dark-5: #;
  --color-ocean-10: #001624;
  --color-ocean-20: #002C48;
  --color-ocean-30: #00436D;
  --color-ocean-40: #005991;
  --color-ocean-50: #006FB5;
  --color-ocean-60: #338CC4;
  --color-ocean-70: #66A9D3;
  --color-ocean-80: #99C5E1;
  --color-ocean-90: #CCE2F0;
  --color-ocean-95: #E6F1F8;
  // Blue Light ************************************************
  --color-blue-light-5: #;
  --color-sky-10: #002633;
  --color-sky-20: #004C66;
  --color-sky-30: #007399;
  --color-sky-40: #0099CC;
  --color-sky-50: #00B9F8;
  --color-sky-60: #33CCFF;
  --color-sky-70: #66D9FF;
  --color-sky-80: #99E5FF;
  --color-sky-90: #CCF2FF;
  --color-sky-95: #E6F9FF;
  // Purple ****************************************************
  --color-Purple-5: #;
  --color-purple-10: #1D072B;
  --color-purple-20: #3A0E56;
  --color-purple-30: #561680;
  --color-purple-40: #731DAB;
  --color-purple-50: #9024D6;
  --color-purple-60: #A650DE;
  --color-purple-70: #BC7CE6;
  --color-purple-80: #D3A7EF;
  --color-purple-90: #E9D3F7;
  --color-purple-95: #F4E9FB;
  // Green *****************************************************
  --color-green-5: #;
  --color-green-10: #062415;
  --color-green-20: #0C482B;
  --color-green-30: #126B40;
  --color-green-40: #188F56;
  --color-green-50: #1EB36B;
  --color-green-60: #4BC289;
  --color-green-70: #78D1A6;
  --color-green-80: #A5E1C4;
  --color-green-90: #D2F0E1;
  --color-green-95: #E9F7F0;
  // Red *******************************************************
  --color-red-5: #;
  --color-red-10: #310D0B;
  --color-red-20: #621B16;
  --color-red-30: #922820;
  --color-red-40: #C3362B;
  --color-red-50: #F44336;
  --color-red-60: #F6695E;
  --color-red-70: #F88E86;
  --color-red-80: #FBB4AF;
  --color-red-90: #FDD9D7;
  --color-red-95: #FEECEB;
  // Yellow ****************************************************
  --color-yellow-5: #;
  --color-yellow-10: #332500;
  --color-yellow-20: #664A00;
  --color-yellow-30: #996E00;
  --color-yellow-40: #CC9300;
  --color-yellow-50: #FFB800;
  --color-yellow-60: #FFC633;
  --color-yellow-70: #FFD466;
  --color-yellow-80: #FFE399;
  --color-yellow-90: #FFF1CC;
  --color-yellow-95: #FFF8E6;
  // Gray *******************************************************
  --color-gray-5: #;
  --color-gray-10: #1C1B1F;
  --color-gray-20: #313033;
  --color-gray-30: #484649;
  --color-gray-40: #605D62;
  --color-gray-50: #787579;
  --color-gray-60: #939094;
  --color-gray-70: #AEAAAE;
  --color-gray-80: #C9C5CA;
  --color-gray-90: #D9D9D9;
  --color-gray-95: #F2F1F2;
  // Gray Purplish **********************************************
  --color-gray-purplish-5: #;
  --color-dove-10: #1D1A22;
  --color-dove-20: #322F37;
  --color-dove-30: #49454F;
  --color-dove-40: #605D66;
  --color-dove-50: #79747E;
  --color-dove-60: #938F99;
  --color-dove-70: #AEA9B4;
  --color-dove-80: #CAC4D0;
  --color-dove-90: #E7E0EC;
  --color-dove-95: #F5EEFA;
  // Gray Dark **************************************************
  --color-gray-dark-5: #;
  --color-raven-10: #040405;
  --color-raven-20: #08070a;
  --color-raven-30: #0c0b0e;
  --color-raven-40: #100e13;
  --color-raven-50: #141218;
  --color-raven-60: #1C1A1E;
  --color-raven-70: #2B292E;
  --color-raven-80: #3F3D42;
  --color-raven-90: #56535A;
  --color-raven-95: #706C75;

  --color-gray-dark-95: #;
  // Base *******************************************************
  --color-base-white: #FFFFFF;
  --color-base-black: #000000;
  --color-background-10: #F1F1F1;
  --color-background-20: var(--color-blue-50);
  /*primary 50-30*/
  --color-state-light: rgba(255, 255, 255, 0.12);

  // ************************ Brand *****************************
  // Primary ****************************************************
  --color-primary-10: var(--color-blue-10);
  --color-primary-20: var(--color-blue-20);
  --color-primary-30: var(--color-blue-30);
  --color-primary-40: var(--color-blue-40);
  --color-primary-50: var(--color-blue-50);
  --color-primary-60: var(--color-blue-60);
  --color-primary-70: var(--color-blue-70);
  --color-primary-80: var(--color-blue-80);
  --color-primary-90: var(--color-blue-90);
  --color-primary-95: var(--color-blue-95);
  --color-secondary-5: var(--color-blue-5);

  // Secondary **************************************************
  --color-secondary-5: var(--color-blue-dark-5);
  --color-secondary-10: var(--color-ocean-10);
  --color-secondary-20: var(--color-ocean-20);
  --color-secondary-30: var(--color-ocean-30);
  --color-secondary-40: var(--color-ocean-40);
  --color-secondary-50: var(--color-ocean-50);
  --color-secondary-60: var(--color-ocean-60);
  --color-secondary-70: var(--color-ocean-70);
  --color-secondary-80: var(--color-ocean-80);
  --color-secondary-90: var(--color-ocean-90);
  --color-secondary-95: var(--color-ocean-95);
  // Tertiary ***************************************************
  --color-tertiary-5: var(--color-purple-5);
  --color-tertiary-10: var(--color-purple-10);
  --color-tertiary-20: var(--color-purple-20);
  --color-tertiary-30: var(--color-purple-30);
  --color-tertiary-40: var(--color-purple-40);
  --color-tertiary-50: var(--color-purple-50);
  --color-tertiary-60: var(--color-purple-60);
  --color-tertiary-70: var(--color-purple-70);
  --color-tertiary-80: var(--color-purple-80);
  --color-tertiary-90: var(--color-purple-90);
  --color-tertiary-95: var(--color-purple-95);
  // Success ****************************************************
  --color-success-5: var(--color-green-5);
  --color-success-10: var(--color-green-10);
  --color-success-20: var(--color-green-20);
  --color-success-30: var(--color-green-30);
  --color-success-40: var(--color-green-40);
  --color-success-50: var(--color-green-50);
  --color-success-60: var(--color-green-60);
  --color-success-70: var(--color-green-70);
  --color-success-80: var(--color-green-80);
  --color-success-90: var(--color-green-90);
  --color-success-95: var(--color-green-95);
  // Error *******************************************************
  --color-error-5: var(--color-red-5);
  --color-error-10: var(--color-red-10);
  --color-error-20: var(--color-red-20);
  --color-error-30: var(--color-red-30);
  --color-error-40: var(--color-red-40);
  --color-error-50: var(--color-red-50);
  --color-error-60: var(--color-red-60);
  --color-error-70: var(--color-red-70);
  --color-error-80: var(--color-red-80);
  --color-error-90: var(--color-red-90);
  --color-error-95: var(--color-red-95);
  // Info ********************************************************
  --color-info-5: var(--color-blue-light-5);
  --color-info-10: var(--color-sky-10);
  --color-info-20: var(--color-sky-20);
  --color-info-30: var(--color-sky-30);
  --color-info-40: var(--color-sky-40);
  --color-info-50: var(--color-sky-50);
  --color-info-60: var(--color-sky-60);
  --color-info-70: var(--color-sky-70);
  --color-info-80: var(--color-sky-80);
  --color-info-90: var(--color-sky-90);
  --color-info-95: var(--color-sky-95);
  // Warning ****************************************************
  --color-warning-5: var(--color-yellow-5);
  --color-warning-10: var(--color-yellow-10);
  --color-warning-20: var(--color-yellow-20);
  --color-warning-30: var(--color-yellow-30);
  --color-warning-40: var(--color-yellow-40);
  --color-warning-50: var(--color-yellow-50);
  --color-warning-60: var(--color-yellow-60);
  --color-warning-70: var(--color-yellow-70);
  --color-warning-80: var(--color-yellow-80);
  --color-warning-90: var(--color-yellow-90);
  --color-warning-95: var(--color-yellow-95);
  // Neutral ****************************************************
  --color-neutral-5: var(--color-gray-5);
  --color-neutral-10: var(--color-gray-10);
  --color-neutral-20: var(--color-gray-20);
  --color-neutral-30: var(--color-gray-30);
  --color-neutral-40: var(--color-gray-40);
  --color-neutral-50: var(--color-gray-50);
  --color-neutral-60: var(--color-gray-60);
  --color-neutral-70: var(--color-gray-70);
  --color-neutral-80: var(--color-gray-80);
  --color-neutral-90: var(--color-gray-90);
  --color-neutral-95: var(--color-gray-95);
  // Neutral Variant *********************************************
  --color-neutral-variant-5: var(--color-gray-purplish-5);
  --color-neutral-variant-10: var(--color-dove-10);
  --color-neutral-variant-20: var(--color-dove-20);
  --color-neutral-variant-30: var(--color-dove-30);
  --color-neutral-variant-40: var(--color-dove-40);
  --color-neutral-variant-50: var(--color-dove-50);
  --color-neutral-variant-60: var(--color-dove-60);
  --color-neutral-variant-70: var(--color-dove-70);
  --color-neutral-variant-80: var(--color-dove-80);
  --color-neutral-variant-90: var(--color-dove-90);
  --color-neutral-variant-95: var(--color-dove-95);
  // Dark *******************************************************
  --color-dark-5: var(--color-gray-dark-5);
  --color-dark-10: var(--color-raven-10);
  --color-dark-20: var(--color-raven-20);
  --color-dark-30: var(--color-raven-30);
  --color-dark-40: var(--color-raven-40);
  --color-dark-50: var(--color-raven-50);
  --color-dark-60: var(--color-raven-60);
  --color-dark-70: var(--color-raven-70);
  --color-dark-80: var(--color-raven-80);
  --color-dark-90: var(--color-raven-90);
  --color-dark-95: var(--color-gray-dark-95);

  --background-color: var(--color-background-10);

  --white-svg-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
  //NEEDED CUSTOM LIGHT COLORS & Ionic light theme variables here
  --color-background-content: var(--color-base-white);
  --color-background-nav: var(--color-base-white);
  --color-button-submit: var(--color-primary-50);
  --color-avatar-change: var(--color-gray-20);
  --color-noBorder-inDark: rgba(241, 241, 241, 1);
  --color-chip: var(--color-primary-30);
  --color-bg10-rv60: var(--color-background-10);
  --color-neutral-20-perm: var(--color-gray-20);
  --color-white-to-gray-90: var(--color-base-white);
  --color-icon-to-white: var(--color-gray-20);
  --linear-gradient: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  --linear-gradient-double: linear-gradient(to bottom, rgba(255, 255, 255, 0) -70%, rgba(255, 255, 255, 1) 9%, rgba(255, 255, 255, 0) 52%), linear-gradient(to top, rgba(255, 255, 255, 0) -51%, rgba(255, 255, 255, 1) 22%, rgba(255, 255, 255, 0) 65%);

  --ion-text-color: var(--color-neutral-20);

  // ************************ Dark Theme Colors ***************************
  [data-theme='dark'] {
    --color-primary-10: var(--color-blue-95);
    --color-primary-20: var(--color-blue-90);
    --color-primary-30: var(--color-blue-80);
    --color-primary-40: var(--color-blue-80);
    --color-primary-50: var(--color-blue-70);
    --color-primary-60: var(--color-blue-60);
    --color-primary-70: var(--color-blue-50);
    --color-primary-80: var(--color-blue-40);
    --color-primary-90: var(--color-blue-30);
    --color-primary-95: var(--color-blue-20);

    --color-secondary-10: var(--color-ocean-95);
    --color-secondary-20: var(--color-ocean-90);
    --color-secondary-30: var(--color-ocean-80);
    --color-secondary-40: var(--color-ocean-80);
    --color-secondary-50: var(--color-ocean-70);
    --color-secondary-60: var(--color-ocean-60);
    --color-secondary-70: var(--color-ocean-50);
    --color-secondary-80: var(--color-ocean-40);
    --color-secondary-90: var(--color-ocean-30);
    --color-secondary-95: var(--color-ocean-20);

    --color-tertiary-10: var(--color-purple-95);
    --color-tertiary-20: var(--color-purple-90);
    --color-tertiary-30: var(--color-purple-80);
    --color-tertiary-40: var(--color-purple-80);
    --color-tertiary-50: var(--color-purple-70);
    --color-tertiary-60: var(--color-purple-60);
    --color-tertiary-70: var(--color-purple-50);
    --color-tertiary-80: var(--color-purple-40);
    --color-tertiary-90: var(--color-purple-30);
    --color-tertiary-95: var(--color-purple-20);

    --color-success-10: var(--color-green-95);
    --color-success-20: var(--color-green-90);
    --color-success-30: var(--color-green-80);
    --color-success-40: var(--color-green-80);
    --color-success-50: var(--color-green-70);
    --color-success-60: var(--color-green-60);
    --color-success-70: var(--color-green-50);
    --color-success-80: var(--color-green-40);
    --color-success-90: var(--color-green-30);
    --color-success-95: var(--color-green-20);

    --color-error-10: var(--color-red-95);
    --color-error-20: var(--color-red-90);
    --color-error-30: var(--color-red-80);
    --color-error-40: var(--color-red-80);
    --color-error-50: var(--color-red-70);
    --color-error-60: var(--color-red-60);
    --color-error-70: var(--color-red-50);
    --color-error-80: var(--color-red-40);
    --color-error-90: var(--color-red-30);
    --color-error-95: var(--color-red-20);

    --color-info-10: var(--color-sky-95);
    --color-info-20: var(--color-sky-90);
    --color-info-30: var(--color-sky-80);
    --color-info-40: var(--color-sky-80);
    --color-info-50: var(--color-sky-70);
    --color-info-60: var(--color-sky-60);
    --color-info-70: var(--color-sky-50);
    --color-info-80: var(--color-sky-40);
    --color-info-90: var(--color-sky-30);
    --color-info-95: var(--color-sky-20);

    --color-neutral-10: var(--color-gray-95);
    --color-neutral-20: var(--color-gray-90);
    --color-neutral-30: var(--color-gray-80);
    --color-neutral-40: var(--color-gray-80);
    --color-neutral-50: var(--color-gray-70);
    --color-neutral-60: var(--color-gray-60);
    --color-neutral-70: var(--color-gray-50);
    --color-neutral-80: var(--color-gray-40);
    --color-neutral-90: var(--color-gray-30);
    --color-neutral-95: var(--color-gray-20);

    --color-warning-10: var(--color-yellow-95);
    --color-warning-20: var(--color-yellow-90);
    --color-warning-30: var(--color-yellow-80);
    --color-warning-40: var(--color-yellow-80);
    --color-warning-50: var(--color-yellow-70);
    --color-warning-60: var(--color-yellow-60);
    --color-warning-70: var(--color-yellow-50);
    --color-warning-80: var(--color-yellow-40);
    --color-warning-90: var(--color-yellow-30);
    --color-warning-95: var(--color-yellow-20);

    //NEEDED CUSTOM DARK COLORS & Ionic dark theme variables here
    --color-background-content: var(--color-raven-60);
    --color-button-submit: var(--color-primary-90);
    --color-background-nav: var(--color-raven-50);
    --color-avatar-change: var(--color-raven-50);
    --color-background-10: var(--color-raven-50);
    --color-noBorder-inDark: var(--color-raven-60);
    --color-chip: var(--color-raven-50);
    --color-bg10-rv60: var(--color-raven-60);
    --color-white-to-gray-90: var(--color-gray-90);
    --linear-gradient: linear-gradient(to bottom, rgba(28, 26, 30, 0), rgba(28, 26, 30, 1));
    --linear-gradient-double: linear-gradient(to bottom, rgba(28, 26, 30, 0) -70%, rgba(28, 26, 30, 0.8) 9%, rgba(28, 26, 30, 0) 52%), linear-gradient(to top, rgba(28, 26, 30, 0) -51%, rgba(28, 26, 30, 0.8) 22%, rgba(28, 26, 30, 0) 65%);
    --color-icon-to-white: var(--white-svg-filter);

    --ion-background-color: var(--color-raven-50);
    --ion-text-color: var(--color-base-white);
    --ion-item-background: var(--color-raven-50);
    --ion-toolbar-background: var(--color-raven-50);
    --ion-background-color-step-800: var(--color-raven-50);
    --ion-color-primary: #222428;
    --ion-color-primary-rgb: 34, 36, 40;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #1e2023;
    --ion-color-primary-tint: #383a3e;

    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;
  }

  // ************************ Spacing ***************************
  --spacing-0: 0px;
  --spacing-1: 2px;
  --spacing-2: 4px;
  --spacing-3: 8px;
  --spacing-4: 12px;
  --spacing-5: 16px;
  --spacing-6: 24px;
  --spacing-7: 32px;
  --spacing-8: 40px;
  --spacing-9: 48px;
  --spacing-10: 56px;
  --spacing-11: 64px;
  --spacing-12: 80px;
  --spacing-13: 96px;
  --spacing-14: 160px;

  // ************************ Radius ***************************
  --radius-03: 32px;
}
